import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`DB Jumping Step Ups 5×2/leg`}</p>
    <p>{`Weighted Shoulder Bridge 5×6`}</p>
    <p>{`then,`}</p>
    <p>{`Death by Back Squat (185/125)(RX+ 225/155)`}</p>
    <p>{`1 Back Squat on minute 1:00`}</p>
    <p>{`2 Back Squats on minute 2:00`}</p>
    <p>{`etc, until failure.`}</p>
    <p>{`Rest 2:00 then,`}</p>
    <p>{`Death by Calorie Assault Bike`}</p>
    <p>{`2 Calories on minute 1:00`}</p>
    <p>{`4 Calories on minute 2:00`}</p>
    <p>{`etc, until failure.`}</p>
    <p>{`Score = total number of completed rounds.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      